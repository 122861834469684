import "@babel/polyfill";
import React, { Component } from 'react';
//import ReactPlayer from 'react-player'

class App extends Component {
  render() {
    return (
	<div>
		<div id="outer">
			<div id="middle">
				<div id="inner">
						<ResponsivePlayer />
				</div>
			</div>	
		</div>
		<div className="fText">
			<span className="blocky">Cura&ccedil;ao Gay Pro</span> 
			<span className="sep">|</span> 
			<span className="blocky"><a href="mailto:info@caribbeangayspringbreakfestival.com">info@caribbeangayspringbreakfestival.com</a></span> 
			<span className="sep1">|</span> 
			<span className="blocky">
				<a href="tel:+5999 462 6111">+5999 462 6111</a> 
				<span className="sep2">|</span> 
				<a href="tel:+1 800 411 0170">+1 800 411 0170</a>
			</span>
		</div>
	</div>
    );
  }
}
class ResponsivePlayer extends Component {
    constructor(props) {
		super(props)
		this.state = {ticketToggle: false};
		this.showTickets = this.showTickets.bind(this);
    }

	showTickets(e) {
		e.preventDefault();
		if(!this.state.ticketToggle) {
			document.getElementById('ticketFrame').className = 'tickets ticketsOn';
			this.setState({ticketToggle: true});
		}
		else {
			document.getElementById('ticketFrame').className = 'tickets';
			this.setState({ticketToggle: false});
		}
	}
	
  render () {
    return (
	<div id="content">
		<div className='top'>
			<img src='top.png' className='header-img' alt="logo" />
		</div>
		<div className='flyer'>
			<img src='flyer-190122.jpg' className='header-img' alt="logo" />
		</div>
		<div className="tickets" id="ticketFrame">
			<a href="http://www.gaypartix.com/parties/Curacao/caribbean/" target="_blank" rel="noopener noreferrer" className="menButton menLink" id="menHotel">Gaypartix (International)</a>
			<a href="https://tibbaa.com/order/zntdqo1hxo" target="_blank" rel="noopener noreferrer" className="menButton menLink" id="menTickets">Tibbaa <span className="breaky"></span>(Cura&ccedil;ao)</a>
		</div>
		<div className="fButtons">
			<a href="http://www.florissuitehotel.com/default-en.html" target="_blank" rel="noopener noreferrer" className="menButton menLink" id="menHotel">Hotel</a>
			<a href="http://www.gaypartix.com/parties/Curacao/caribbean/" onClick={this.showTickets} target="_blank" rel="noopener noreferrer" className="menButton menLink" id="menTickets">Tickets</a>
			<a href="https://www.facebook.com/caribbeangayspringbreakfestival/" target="_blank" rel="noopener noreferrer" className="menButton menSocial" id="menFB">Facebook</a>
			<a href="https://www.instagram.com/caribbeangayspringbreak" target="_blank" rel="noopener noreferrer" className="menButton menSocial" id="menIG">Instagram</a>
		</div>
	</div>
	/*
      <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          url='/caribbean-gay-spring-break-2.mp4'
          width='100%'
          height='100%'
		  playing={true}
		  loop={true}
		  preload={true}
		  volume={0}
		  muted={true}
        />
      </div>
	*/
    )
  }
}

export default App;
